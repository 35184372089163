import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { styles } from '@/styles/shared-styles';

import '@/components/language-selector';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: Boolean }) enableBack: boolean = false;

  static styles = [
    styles,
    css`
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(87, 87, 87, 0.5) 100%
          ),
          #2b2b2b;
        color: white;
        height: var(--header-height);
        padding: 0 16px;
        position: fixed;
        left: env(titlebar-area-x, 0);
        top: env(titlebar-area-y, 0);
        width: env(titlebar-area-width, 100%);
        -webkit-app-region: drag;
      }
      #back-button-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 12em;
      }
      .languages {
        color: var(--color-white);
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    `,
  ];

  render() {
    return html`
      <header>
        <div id="back-button-block">
          ${this.enableBack
            ? html`<a href="/"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none">
                  <path
                    d="M14.799 19.1786L20.9916 12.9859L21.169 12.8085L20.991 12.6317L20.1762 11.8226L19.9994 11.6471L19.8232 11.8232L11.8232 19.8232L11.6464 20L11.8232 20.1768L19.8232 28.1768L19.9994 28.3529L20.1762 28.1774L20.991 27.3683L21.169 27.1915L20.9916 27.0141L14.799 20.8214H28H28.25V20.5714V19.4286V19.1786H28H14.799Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5" /></svg
              ></a>`
            : html`<img
                src="/app/assets/images/logo-th.svg"
                alt="Turbo's Hoet" />`}
        </div>
        <div class="languages">
          <language-selector></language-selector>
        </div>
      </header>
    `;
  }
}

