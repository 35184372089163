// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md
import { html } from 'lit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (!(globalThis as any).URLPattern) {
  await import('urlpattern-polyfill');
}

import { Router } from '@thepassle/app-tools/router.js';
import { redirect } from '@thepassle/app-tools/router/plugins/redirect.js';

// pges
import './pages/app-home';
import './pages/app-iframe';

// router config
export const router = new Router({
  routes: [
    {
      path: '/',
      plugins: [redirect('/app/')],
    },
    {
      path: '/app/',
      title: "Turbo's Hoet",
      render: () => html`<app-home></app-home>`,
    },
    {
      path: '/app/iframe/:pageType',
      title: 'Page',
      render: () => html`<app-iframe></app-iframe>`,
    },
  ],
});

