// Lit libraries
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { translate as t } from 'lit-i18n';

// Shoelace components
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

// shared styling
import { styles } from '@/styles/shared-styles';

// own libs
import { router } from '@/router';
import { getPageURL } from '@/lib/pages';
import { getCurrentLanguage } from '@/lib/languages';

@customElement('app-iframe')
export class AppIframe extends LitElement {
  render() {
    const pageNotFoundHTML = html`
      <app-header ?enableBack="${true}"></app-header>
      <main>${t('page_not_found')}</main>
    `;

    if (!router.context.params.pageType) {
      console.error('pageType is not defined');
      return pageNotFoundHTML;
    }

    const language = getCurrentLanguage();

    const page = getPageURL(router.context.params.pageType);
    if (!page) {
      console.error(
        `page "${router.context.params.pageType}" not found for language "${language}". Is it defined in src/lib/pages.ts?`
      );
      return pageNotFoundHTML;
    }

    return html`
      <app-header ?enableBack="${true}"></app-header>
      <main>
        <sl-spinner></sl-spinner>
        <iframe src="${page}" frameborder="0"></iframe>
      </main>
    `;
  }

  static styles = [
    styles,
    css`
      iframe {
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        z-index: 20;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: var(--header-height);
      }
      sl-spinner {
        --indicator-color: #000;
        --track-color: #fff;
        --track-width: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5rem;
        z-index: 10;
      }
    `,
  ];
}

