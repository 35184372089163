let notificationPermissionRequested = false;

export function setNotificationPermissionRequested(value: boolean) {
  notificationPermissionRequested = value;
}

export function getNotificationPermissionRequested(): boolean {
  return notificationPermissionRequested;
}

