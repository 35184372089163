import { css } from 'lit';

/* these styles can be imported from any component:

import { styles } from '../styles/shared-styles';

  static styles = [
    styles,
    css`
      // your styles here
    `
  ];
*/
export const styles = css`
  * {
    box-sizing: border-box;
  }

  ::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }

  app-header {
    position: relative;
    z-index: var(--z-index-backdrop);
  }

  main {
    padding: var(--header-height) 1rem 0 1rem;
  }
`;

