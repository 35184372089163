import { LitElement, css, html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, customElement } from 'lit/decorators.js';
import { styles } from '@/styles/shared-styles';

import '@shoelace-style/shoelace/dist/components/button/button.js';

@customElement('app-button')
export class AppButton extends LitElement {
  @property({ type: String }) href = '';
  @property({ type: String }) icon = '';
  @property({ type: String }) iconRight = '';
  @property({ type: String }) color = '';
  @property({ type: String }) title = '';
  @property({ type: String }) subtitle = '';
  @property({ type: String }) whatsapp = ''; // extra Whatsapp button, eg '31646920696'

  static styles = [
    styles,
    css`
      .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: var(--color-blue);
        border-radius: 15px;
        color: #fff;
        min-height: 64px;
        border: 0;
        padding: 0 24px;
        gap: 1rem;
        width: 100%;
        font-size: 18px;
        line-height: normal;
        letter-spacing: -0.36px;
        text-transform: uppercase;
        text-decoration: none;
        white-space: initial;
        box-shadow: 0px 4px 3px 0px rgba(183, 183, 183, 0.25),
          0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      }
      .button__icon--right {
        margin-left: auto;
      }
      .button--hasicon {
        justify-content: flex-start;
      }
      .button.button--black {
        font-weight: 500;
        line-height: normal;
        min-height: 72px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(87, 87, 87, 0.5) 100%
          ),
          #2b2b2b;
      }
      .button--has-subtitle {
        min-height: 72px;
      }
      .button--has-subtitle .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
      .button--has-whatsapp {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .title {
        font-weight: 700;
        text-align: center;
      }
      .subtitle {
        font-weight: 500;
      }
      .button__whatsappbutton {
        width: 100%;
        height: 54px;
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        background: #25d366;
        color: #fff;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        text-decoration: none;
        border-radius: 0px 0px 15px 15px;
        box-shadow: 0px 4px 3px 0px rgba(183, 183, 183, 0.25),
          0px 2px 4px 0px rgba(0, 0, 0, 0.05);
      }
    `,
  ];

  firstUpdated() {
    // document.addEventListener('click', this.closeDialog);
  }

  render() {
    const classes: { [key: string]: boolean } = { button: true };
    if (this.color) {
      classes['button--' + this.color] = true; // add class "button--<color>", ie "button--blue"
    }
    if (this.icon) {
      classes['button--hasicon'] = true;
    }

    if (this.subtitle) {
      classes['button--has-subtitle'] = true;
    }

    if (this.whatsapp) {
      classes['button--has-whatsapp'] = true;
    }

    let iconHTML;
    if (this.icon) {
      iconHTML = html`<img
        class="button__icon"
        src="/app/assets/images/${this.icon}.svg"
        alt=""
        aria-label="hidden" />`;
    }

    let iconRightHTML;
    if (this.iconRight) {
      iconRightHTML = html`<img
        class="button__icon button__icon--right"
        src="/app/assets/images/${this.iconRight}.svg"
        alt=""
        aria-label="hidden" />`;
    }

    // FIXME: direct Whatsapp links don't seem to work, at least not on iOS
    // might be worth the effort to debug using Xcode:
    // href="whatsapp://send?phone=${this.whatsapp}"
    // for now, we'll just just the "safe" URL eg api.whatsapp.com

    return html`<a href="${this.href}" class=${classMap(classes)}>
        ${iconHTML}
        <div class="content">
          <div class="title">${this.title}</div>
          ${this.subtitle
            ? html`<div class="subtitle">${this.subtitle}</div>`
            : ''}
        </div>
        ${iconRightHTML}
      </a>
      ${this.whatsapp
        ? html`<a
            href="https://api.whatsapp.com/send/?phone=${this.whatsapp}"
            class="button__whatsappbutton"
            target="${this.whatsapp ? '_blank' : 'self'}">
            <img
              src="/app/assets/images/whatsapp.svg"
              height="24"
              alt=""
              aria-label="hidden" />Whatsapp
          </a>`
        : ''}`;
  }
}

