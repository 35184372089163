import i18next from 'i18next';
import { initLitI18n } from 'lit-i18n';

await i18next.use(initLitI18n).init({
  lng: getCurrentLanguage(),
  // debug: true,
  resources: {
    nl: {
      translation: {
        // note the "soft hyphen" in some Dutch texts (https://unicode-explorer.com/c/00AD)
        call_your_turbospecialist: 'Bel jouw turbospecialist',
        call_your_turbospecialist_dialog: 'Bel jouw Turbospecialist',
        register_your_turbo: 'Registreer jouw turbo',
        photo_video_diagnosis: 'Foto/video diagnose',
        knowledge_base: 'Kennisbank',
        submit_claim: 'Garantieclaim',
        webshop: 'TurboShop',
        turbonews: 'TurboNieuws',
        page_not_found: 'Pagina niet gevonden',
        belgium: 'België',
        france: 'Frankrijk',
        netherlands: 'Nederland',
        whatsapp: 'Whatsapp',
        close_window: 'sluit venster',
      },
    },
    fr: {
      translation: {
        call_your_turbospecialist: 'Appelez votre spécialiste Turbo',
        call_your_turbospecialist_dialog: 'Appelez votre spécialiste Turbo',
        register_your_turbo: 'Enregistrez votre turbo',
        photo_video_diagnosis: 'Diagnostique photo/vidéo',
        knowledge_base: 'Base de connaissances',
        submit_claim: 'Demande de garantie',
        webshop: 'TurboShop',
        turbonews: 'TurboNews',
        page_not_found: 'Page non trouvée',
        belgium: 'Belgique',
        france: 'France',
        netherlands: 'Pays-Bas',
        whatsapp: 'Whatsapp',
        close_window: 'fermer la fenêtre',
      },
    },
  },
});

const languages = ['nl', 'fr'] as const;
export const locales = ['nl', 'nl-be', 'fr-be', 'fr'] as const;

type Language = (typeof languages)[number];
export type Locale = (typeof locales)[number];

export function getCurrentLanguage(): Language {
  const locale = getCurrentLocale();
  return getLanguageByLocale(locale) || 'nl';
}

export function getCurrentLocale(): Locale {
  const locale = localStorage.getItem('locale') as Locale;
  return locale || 'nl';
}

export function setLanguageByLocale(locale: Locale) {
  localStorage.setItem('locale', locale);

  const language = getLanguageByLocale(locale);
  i18next.changeLanguage(language, (err) => {
    if (err) {
      return console.log('something went wrong loading', err);
    }
    // t('key');
  });
}

function getLanguageByLocale(locale: Locale): Language {
  const language = locale.substring(0, 2).toLowerCase() as Language; // nl, fr
  if (!['nl', 'fr'].includes(language)) {
    console.error(`Invalid locale "${locale}"`);
    return 'nl';
  }

  return language;
}

