// Lit libraries
import { LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

// Shoelace styling
import '@shoelace-style/shoelace/dist/themes/light.css';

// global styling
import '@/styles/global.css';

// own libs
import { router } from '@/router';
import {
  initializeFirebase,
  requestNotificationPermission,
} from './lib/firebase';

// own components
import '@/components/header';

import {
  getNotificationPermissionRequested,
  setNotificationPermissionRequested,
} from '@/lib/notificationState';

@customElement('app-index')
export class AppIndex extends LitElement {
  async firstUpdated() {
    // Initialize Firebase
    await initializeFirebase();

    // Try to request notification permission on app start, only on Chrome
    // if (
    //   !getNotificationPermissionRequested() &&
    //   /chrome/i.test(navigator.userAgent)
    // ) {
    //   try {
    //     await requestNotificationPermission();
    //     setNotificationPermissionRequested(true);
    //     console.log(
    //       'Notification permission requested successfully on app start (Chrome)'
    //     );
    //   } catch (error) {
    //     console.log(
    //       'Failed to request notification permission on app start (Chrome):',
    //       error
    //     );
    //   }
    // }

    router.addEventListener('route-changed', async () => {
      if (!getNotificationPermissionRequested()) {
        try {
          await requestNotificationPermission();
          setNotificationPermissionRequested(true);
          console.log(
            'Notification permission requested successfully in app-index'
          );
        } catch (error) {
          console.log(
            'Failed to request notification permission in app-index:',
            error
          );
        }
      }

      if ('startViewTransition' in document) {
        // Use a type assertion to avoid the 'any' type
        (
          document as Document & {
            startViewTransition: (callback: () => void) => void;
          }
        ).startViewTransition(() => this.requestUpdate());
      } else {
        this.requestUpdate();
      }
    });
  }

  render() {
    // router config can be found in src/router.ts
    return router.render();
  }
}

