import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { styles } from '@/styles/shared-styles';

import {
  locales,
  setLanguageByLocale,
  getCurrentLocale,
  Locale,
} from '@/lib/languages';

@customElement('language-selector')
export class LanguageSelector extends LitElement {
  @property({ type: String }) selectedLocale = '';

  constructor() {
    super();
    this.selectedLocale = getCurrentLocale();
  }

  static styles = [
    styles,
    css`
      .container {
        position: relative;
        z-index: var(--z-index-language-selector);
        height: 21px;
      }
      .backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        backdrop-filter: blur(2px);
      }
      .close-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
      }
      .items {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(var(--header-height));
        align-items: flex-end;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
      }
      .item {
        text-align: center;
        background: var(--color-yellow);
        padding: 8px 8px;
        color: var(--color-black);
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 15px;
        text-decoration: none;
        white-space: nowrap;
      }
      .item.selected {
        background: var(--color-white);
        font-weight: 700;
      }
      .currentlocale {
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        opacity: 1;
        transition: opacity 0.3s;
      }
      .component.show .backdrop,
      .component.show .close-icon,
      .component.show .items {
        opacity: 1;
        pointer-events: auto;
      }
      .component.show .currentlocale {
        opacity: 0;
        pointer-events: none;
      }
    `,
  ];

  show(event?: Event) {
    if (event?.target) {
      event.preventDefault();
    }
    this._toggle(true);
  }

  hide(event?: Event) {
    if (event?.target) {
      event.preventDefault();
    }
    this._toggle(false);
  }

  _toggle(show: boolean) {
    const select = this.renderRoot.querySelector('#component') as HTMLElement;
    if (show) {
      select.classList.add('show');
    } else {
      select.classList.remove('show');
    }
  }

  selectLocale(event: Event) {
    if (!event || !event.target) {
      return;
    }
    event.preventDefault();
    const target = event.target as HTMLElement;
    this.selectedLocale = target.getAttribute('data-locale') as string;
    setLanguageByLocale(this.selectedLocale as Locale);
    this.hide();
  }

  render() {
    return html`
    <div class="component" id="component">
      <div class="backdrop"></div>
      <div class="container">
        <img
          class="close-icon"
          src="/app/assets/images/close-white.svg"
          @click="${this.hide}"
          alt="Close" />
        <a href="#" class="currentlocale" @click="${this.show}">
          ${this.selectedLocale}
        </a>
        <div class="items" id="select">
          ${locales.map(
            (locale) =>
              html`<a
                href="#"
                @click="${this.selectLocale}"
                class="item ${locale == this.selectedLocale ? 'selected' : ''}"
                data-locale="${locale}">
                ${locale.toUpperCase()}
              </a>`
          )}
        </sl-select>
      </div>
    </div>`;
  }
}

