import { getCurrentLanguage } from '@/lib/languages';

const urls = {
  register_your_turbo: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/register/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/register/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/register/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/register/fr',
  },
  photo_video_diagnosis: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/live/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/live/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/live/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/live/fr',
  },
  knowledge_base: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/diagnose/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/diagnose/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/diagnose/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/diagnose/fr',
  },
  submit_claim: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/claim/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/claim/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/claim/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/claim/fr',
  },
  webshop: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/shop/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/shop/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/shop/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/shop/fr',
  },
  turbonews: {
    'nl': 'https://turbopartner.th-group.eu/app/redir/news/nl',
    'nl-be': 'https://turbopartner.th-group.eu/app/redir/news/nl-be',
    'fr-be': 'https://turbopartner.th-group.eu/app/redir/news/fr-be',
    'fr': 'https://turbopartner.th-group.eu/app/redir/news/fr',
  },
};

type PageType = keyof typeof urls;

export function getPageURL(pageType: PageType) {
  // get language from local storage, fallback to nl
  const language = getCurrentLanguage();

  const pageInfo = urls[pageType];
  if (!pageInfo) {
    throw new Error(`Page "${pageType}" not found`);
  }

  // get url from urls object
  const url = pageInfo[language];
  if (!url) {
    throw new Error(
      `Page "${pageType}" not found for language "${language}". Is it set in src/lib/pages.ts?`
    );
  }

  return pageInfo[language];
}

