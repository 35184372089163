import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  Messaging,
  MessagePayload,
} from 'firebase/messaging';
import { createClient } from '@supabase/supabase-js';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: 'turbo-push-a2054.firebaseapp.com',
  projectId: 'turbo-push-a2054',
  storageBucket: 'turbo-push-a2054.appspot.com',
  messagingSenderId: '962632643440',
  appId: '1:962632643440:web:d92cab602402a46e5d95b5',
};

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

let messaging: Messaging | null = null;

export async function initializeFirebase() {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);

  // Register the service worker here
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/app/firebase-messaging-sw.js',
        { scope: '/app/' }
      );
      console.log(
        'Firebase messaging service worker registered:',
        registration
      );
    } catch (error) {
      console.error(
        'Error registering Firebase messaging service worker:',
        error
      );
    }
  }
}

export async function requestNotificationPermission() {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
    getAndStoreFirebaseToken(); // Remove await
    return permission;
  }

  throw new Error(
    `Unable to get permission to notify. Permission details: ${JSON.stringify(
      permission
    )}`
  );
}

async function getAndStoreFirebaseToken() {
  if (!messaging) {
    console.error('Messaging is not initialized');
    return;
  }

  navigator.serviceWorker.ready.then((registration) => {
    getToken(messaging!, {
      vapidKey:
        'BJtQDak1ITfoqqqd_QsAZLwl26GVVrQIgSc94Tn129k-JdhzrSxNRepjC0JJUU8ycLn7Ygh-YD8yKLVYOtkJFdo',
      serviceWorkerRegistration: registration,
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Firebase token:', currentToken);
          // Call storeTokenInSupabase without awaiting it
          storeTokenInSupabase(currentToken).catch((error) => {
            console.error('Error storing token in Supabase:', error);
          });
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((error) => {
        console.error('An error occurred while retrieving token:', error);
      });
  });
}

function storeTokenInSupabase(token: string): Promise<void> {
  return new Promise((resolve, reject) => {
    supabase
      .from('firebase_tokens')
      .upsert({ token: token }, { onConflict: 'token' })
      .then(({ data, error }) => {
        if (error) {
          reject(error);
        } else {
          console.log('Token stored in Supabase:', data);
          resolve();
        }
      })
      // @ts-expect-error we don't use await on purpose
      .catch(reject);
  });
}

export function setupMessageListener(
  callback: (payload: MessagePayload) => void
) {
  if (!messaging) {
    console.error('Messaging is not initialized');
    return;
  }

  onMessage(messaging, (payload) => {
    console.log('Message received:', payload);
    callback(payload);
  });
}

