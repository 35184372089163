export const appSettings = {
  contact: {
    tel: {
      belgium: {
        number: '+3251275050',
        title: '+32 51 27 50 50',
      },
      france: {
        number: '+33320769950',
        title: '+33 320 76 99 50',
      },
      netherlands: {
        number: '+31332473090',
        title: '+31 33 247 3090',
      },
    },
    whatsapp: {
      netherlands: {
        number: '31646920696',
      },
    },
  },
};

